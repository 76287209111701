import { mapGetters } from 'vuex'
import mixinFormatter from '@/mixins/formatter'

export default {
  mixins: [
    mixinFormatter,
  ],
  computed: {
    ...mapGetters({
      subscription: 'billing/subscription',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      isSubscriptionCanceled: 'billing/isSubscriptionCanceled',
      isLatestInvoicePaid: 'billing/isLatestInvoicePaid',
      isLatestInvoiceUnpaid: 'billing/isLatestInvoiceUnpaid',
    }),
    notStartedFreeTrial() {
      return this.subscription && this.isSubscriptionInactive && !this.subscription.trialActive
    },
    cancelledAndPeriodNotEnded() {
      return this.isSubscriptionCanceled && this.isLatestInvoicePaid && this.subscription.cancelDate * 1000 > Date.now()
    },
    cancelledAndPeriodEnded() {
      return this.isSubscriptionCanceled && this.isLatestInvoicePaid && this.subscription.cancelDate * 1000 < Date.now()
    },
    canceledAndPaymentNotThrough() {
      return this.isSubscriptionCanceled && this.isLatestInvoiceUnpaid
    },
    notCanceledAndPaymentNotThrough() {
      return !this.isSubscriptionCanceled && this.isLatestInvoiceUnpaid
    },
    subscriptionNotificationText() {
      if (this.notStartedFreeTrial) {
        return this.$t('subscriptionNotificationNotStartedFreeTrial')
      }
      if (this.cancelledAndPeriodNotEnded) {
        return this.$t(
          'subscriptionNotificationCancelledAndPeriodNotEnded',
          {
            date: this.getFormattedDate(this.subscription.cancelDate),
          },
        )
      }
      if (this.cancelledAndPeriodEnded) {
        return this.$t('subscriptionNotificationCancelledAndPeriodEnded')
      }
      if (this.canceledAndPaymentNotThrough) {
        return this.$t('subscriptionNotificationCanceledAndPaymentNotThrough')
      }
      if (this.notCanceledAndPaymentNotThrough) {
        return this.$t('subscriptionNotificationNotCanceledAndPaymentNotThrough')
      }
      return ''
    },
  },
}
