<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationsNew.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('globalNotificationTitle') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          <i18n path="globalNotificationNewAmount">
            <template v-slot:slot>
              {{ notificationsNew.length }}
            </template>
          </i18n>
        </b-badge>
      </div>
    </li>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
        @click="onClickNotification(notification)"
      >
        <b-media :class="[{ 'notification-new': notificationsNew.find(i => i.id === notification.id) }]">
          <p class="media-heading">
            <span class="font-weight-bold">{{ notification.message }}</span>
          </p>
          <small class="notification-text">{{ notification.dateTime }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <li class="dropdown-menu-footer">
      <b-button
        variant="outline-primary"
        block
        :disabled="!notificationsNew.length"
        @click="onClickReadAll"
      >
        {{ $t('globalNotificationReadAll') }}
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BLink,
  BMedia,
  BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters, mapActions } from 'vuex'
import { DateTime } from 'luxon'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BLink,
    BMedia,
    VuePerfectScrollbar,
    BButton,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  computed: {
    ...mapGetters({
      notificationsChecked: 'main/notificationsChecked',
    }),
    notifications() {
      return [
        // {
        //   id: 'X', // +1 to previous value
        //   dateTime: DateTime
        //     .fromJSDate(new Date('X')) // new Date().toUTCString()
        //     .setLocale(navigator.language)
        //     .toLocaleString(DateTime.DATETIME_SHORT),
        //   message: this.$t('globalNotification.X'), // create and add new key to translations
        // },
        {
          id: 2,
          dateTime: DateTime
            .fromJSDate(new Date('Tue, 16 Jul 2024 07:30:14 GMT'))
            .setLocale(navigator.language)
            .toLocaleString(DateTime.DATETIME_SHORT),
          message: this.$t('globalNotification.addedTaxesToOrderPayments'),
        },
        {
          id: 1,
          dateTime: DateTime
            .fromJSDate(new Date('Fri, 12 Jul 2024 16:54:16 GMT'))
            .setLocale(navigator.language)
            .toLocaleString(DateTime.DATETIME_SHORT),
          message: this.$t('globalNotification.addedOrdersHistory'),
        },
      ]
    },
    notificationsNew() {
      return this.notifications.filter(i => !this.notificationsChecked.find(j => j === i.id))
    },
  },
  methods: {
    ...mapActions({
      setNotificationsChecked: 'main/setNotificationsChecked',
    }),
    updateNotificationsChecked(array) {
      this.setNotificationsChecked(
        [...new Set([...this.notificationsChecked, ...array])].sort((a, b) => a - b),
      )
    },
    onClickNotification(notification) {
      if (!this.notificationsChecked.find(i => i === notification.id)) {
        this.updateNotificationsChecked([notification.id])
      }
    },
    onClickReadAll() {
      this.updateNotificationsChecked(this.notifications.map(i => i.id))
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/base/bootstrap-extended/_variables.scss'

  .notification-new
    background-color: $dropdown-link-hover-bg
</style>
