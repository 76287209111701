<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    AppBreadcrumb,
  },
  computed: {
    ...mapGetters({
      organizations: 'organizations/organizations',
      restaurants: 'restaurant/restaurants',
    }),
  },
  async created() {
    await Promise.all([
      this.getOrganizations(),
      this.getRestaurants(),

      this.getCurrencies(),
      this.getMeasurementUnits(),
      this.getLanguages(),
      this.getAllergens(),
    ])

    if (!this.organizations.length) {
      await this.createOrganization()
    }

    if (!this.restaurants.length) {
      await this.createRestaurant({ name: this.$t('My Restaurant') })
    }
    this.setCurrentRestaurantId(this.restaurants[this.restaurants.length - 1].id)

    await Promise.all([
      this.getSubscription(),
      this.getInvoices(),
      this.getBillingConfig(),

      this.getBadgesTagsAllData(),
    ])
  },
  methods: {
    ...mapActions({
      getCurrencies: 'dictionaries/getCurrencies',
      getMeasurementUnits: 'dictionaries/getMeasurementUnits',
      getLanguages: 'dictionaries/getLanguages',
      getAllergens: 'dictionaries/getAllergens',

      getOrganizations: 'organizations/getOrganizations',
      createOrganization: 'organizations/createOrganization',
      getRestaurants: 'restaurant/getRestaurants',
      createRestaurant: 'restaurant/createRestaurant',

      setCurrentRestaurantId: 'restaurant/setCurrentRestaurantId',

      getSubscription: 'billing/getSubscription',
      getInvoices: 'billing/getInvoices',
      getBillingConfig: 'billing/getBillingConfig',

      getBadgesTagsAllData: 'badgesTags/getBadgesTagsAllData',
    }),
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
}
</script>
