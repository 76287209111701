<template>
  <svg
    class="icon-custom"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.21875 5.20117C7.21875 4.21741 8.01624 3.41992 9 3.41992C9.98376 3.41992 10.7812 4.21741 10.7812 5.20117C10.7812 6.18493 9.98376 6.98242 9 6.98242C8.01624 6.98242 7.21875 6.18493 7.21875 5.20117ZM9 1.91992C7.18782 1.91992 5.71875 3.38899 5.71875 5.20117C5.71875 7.01336 7.18782 8.48242 9 8.48242C10.8122 8.48242 12.2812 7.01336 12.2812 5.20117C12.2812 3.38899 10.8122 1.91992 9 1.91992ZM4.78125 11.0137C3.79749 11.0137 3 11.8112 3 12.7949C3 13.7787 3.79749 14.5762 4.78125 14.5762C5.76501 14.5762 6.5625 13.7787 6.5625 12.7949C6.5625 11.8112 5.76501 11.0137 4.78125 11.0137ZM1.5 12.7949C1.5 10.9827 2.96907 9.51367 4.78125 9.51367C6.59343 9.51367 8.0625 10.9827 8.0625 12.7949C8.0625 14.6071 6.59343 16.0762 4.78125 16.0762C2.96907 16.0762 1.5 14.6071 1.5 12.7949ZM13.2188 11.0137C12.235 11.0137 11.4375 11.8112 11.4375 12.7949C11.4375 13.7787 12.235 14.5762 13.2188 14.5762C14.2025 14.5762 15 13.7787 15 12.7949C15 11.8112 14.2025 11.0137 13.2188 11.0137ZM9.9375 12.7949C9.9375 10.9827 11.4066 9.51367 13.2188 9.51367C15.0309 9.51367 16.5 10.9827 16.5 12.7949C16.5 14.6071 15.0309 16.0762 13.2188 16.0762C11.4066 16.0762 9.9375 14.6071 9.9375 12.7949Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDots',
}
</script>
