export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'PieChartIcon',
  },
  {
    title: 'Orders',
    route: 'orders',
  },
  {
    title: 'Menus',
    route: 'menu-management',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Events',
    route: 'events',
    icon: 'ClockIcon',
  },
  {
    title: 'Restaurant',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Business settings',
        route: 'settings',
        icon: 'CircleIcon',
      },
      {
        title: 'Services',
        route: 'add-ons_settings',
        icon: 'CircleIcon',
      },
      {
        title: 'Integrations',
        route: 'integrations',
        icon: 'CircleIcon',
      },
      {
        title: 'Floor zones',
        route: 'zones',
        icon: 'CircleIcon',
      },
      {
        title: 'Digital Menu Designer',
        route: 'themes',
        icon: 'CircleIcon',
      },
      {
        title: 'Paper Menu Designer',
        route: 'paper-menu-designer',
        icon: 'CircleIcon',
      },
      {
        title: 'QR code designer',
        route: 'qr',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Account',
    children: [
      // {
      //   title: 'Restaurants',
      //   route: 'restaurants',
      //   icon: 'CircleIcon',
      // },
      {
        title: 'Billing',
        route: 'billing',
        icon: 'CircleIcon',
      },
      {
        title: 'Additional services',
        route: 'add-ons',
        icon: 'CircleIcon',
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'CircleIcon',
      },
    ],
  },
  // {
  //   title: 'FAQ',
  //   route: 'faq',
  //   icon: 'InfoIcon',
  // },
  // {
  //   title: 'Trash Bin',
  //   route: 'trash-bin',
  //   icon: 'TrashIcon',
  //   class: 'position-absolute fixed-bottom mb-75',
  // },
]
