<template>
  <b-dropdown
    variant="flat-secondary"
    class="dropdown-language"
    right
  >
    <template #button-content>
      {{ locales.find(i => i.locale === locale).name }}
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      :disabled="localeObj.locale === $i18n.locale"
      @click="updateLocale(localeObj.locale)"
    >
      {{ localeObj.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import { localize } from 'vee-validate'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      locales: [
        {
          locale: 'en',
          name: 'English',
        },
        {
          locale: 'es',
          name: 'Español',
        },
        {
          locale: 'ru',
          name: 'Русский',
        },
        {
          locale: 'bg',
          name: 'Български',
        },
        {
          locale: 'uk',
          name: 'Українська',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      locale: 'main/locale',
    }),
  },
  methods: {
    ...mapActions({
      setLocale: 'main/setLocale',
    }),
    updateLocale(newValue) {
      localize(newValue)
      this.$i18n.locale = newValue
      this.setLocale(newValue)
    },
  },
}
</script>

<style lang="sass">
  .dropdown-language
    .btn-flat-secondary
      &:focus
        background-color: transparent
</style>
