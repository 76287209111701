<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in itemsToRender"
      :key="item.header || item.title"
      :item="item"
      :class="[item.class]"
    >
      <icon-dots v-if="item.title === 'Account'" />
      <icon-order v-if="item.title === 'Orders'" />
    </component>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import IconOrder from '@/components/icon/IconOrder.vue'
import IconDots from '@/components/icon/IconDots.vue'
import { mapGetters } from 'vuex'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    IconOrder,
    IconDots,
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
    }),
    itemsToRender() {
      return this.items.filter(i => {
        if (i.title === 'Events') {
          return this.restaurant && this.restaurant.eventsSettings.enabled
        }
        if (i.title === 'Orders') {
          return this.restaurant && this.restaurant.ordersSettings.enabled
        }
        return true
      })
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>
