<template>
  <b-button
    v-if="name && $route.name !== 'billing'"
    variant="outline-primary"
    class="mr-1"
    @click="$router.push({ name: 'billing' })"
  >
    {{ name }}
  </b-button>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import mixinSubscriptionNotification from '@/mixins/subscription-notification'

export default {
  name: 'SubscriptionNotificationLink',
  components: {
    BButton,
  },
  mixins: [
    mixinSubscriptionNotification,
  ],
  computed: {
    name() {
      if (this.notStartedFreeTrial) {
        return this.$t('Start Free Trial')
      }
      if (this.cancelledAndPeriodNotEnded || this.cancelledAndPeriodEnded || this.canceledAndPaymentNotThrough) {
        return this.$t('Resubscribe Now')
      }
      if (this.notCanceledAndPaymentNotThrough) {
        return this.$t('Update Billing')
      }
      return ''
    },
  },
}
</script>
