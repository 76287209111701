<template>
  <svg
    class="icon-custom"
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0495 2.29654L9.99973 2.46199L10.1705 2.488C13.8852 3.05366 16.7406 6.26998 16.7406 10.14C16.7406 10.3699 16.5543 10.5563 16.3243 10.5563H1.67623C1.44625 10.5563 1.25994 10.3699 1.25994 10.14C1.25994 6.26998 4.11533 3.05373 7.83002 2.488L8.00081 2.46199L7.95109 2.29654C7.92098 2.19635 7.90443 2.09082 7.90443 1.98158C7.90443 1.37748 8.39617 0.885742 9.00027 0.885742C9.60438 0.885742 10.0961 1.37748 10.0961 1.98158C10.0961 2.09082 10.0796 2.19635 10.0495 2.29654ZM14.6008 9.16047C14.8418 9.16047 15.032 8.93806 14.9718 8.69391C14.3111 6.01054 11.8888 4.02026 9.00127 4.02026C6.11375 4.02026 3.69146 6.01054 3.03071 8.69391C2.97059 8.93806 3.16072 9.16047 3.40178 9.16047H14.6008Z"
    />
    <path
      d="M0.525391 12.2887C0.525391 11.833 0.894756 11.4637 1.35039 11.4637H16.6504C17.106 11.4637 17.4754 11.833 17.4754 12.2887C17.4754 12.7443 17.106 13.1137 16.6504 13.1137H1.35039C0.894756 13.1137 0.525391 12.7443 0.525391 12.2887Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconOrder',
}
</script>
