<template>
  <p class="clearfix mb-0">
    <!-- <span class="float-md-left d-none d-md-block">Hand-crafted &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span> -->

    <span class="float-md-right d-block d-md-inline-block font-small-3">
      © {{ new Date().getFullYear() }} {{ appName }} Technology, Inc.
      <span class="d-none d-sm-inline-block">{{ $t('All Rights Reserved') }}</span>
    </span>
  </p>
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  data() {
    return {
      appName: $themeConfig.app.appName,
    }
  },
}
</script>
